import React, { useEffect } from 'react';
import Layout from '../components/app/Layout';
import * as Sentry from '@sentry/nextjs';
import {Box} from "@chakra-ui/layout";
import {TitleSection} from "../components/TitleSection";

export default function Error({ err }) {
    useEffect(() => {
        if (err) {
            Sentry.withScope((scope) => {
                scope.setLevel(Sentry.Fatal);
                Sentry.captureException(err);
            });
            Sentry.flush(2000);
        }
    }, [err]);

    return (
        <Layout>
            <Box
                minWidth={'calc(100% - 16rem)'}
                width={
                    { base: '100%', md: 'calc(100% - 16rem)' }
                }
                style={{
                    transition: '.1s ease-in-out',
                }}
            >
                <TitleSection
                    title="Une erreur est survenue"
                    iconName={'remove'}
                />
            </Box>
        </Layout>
    );
}
